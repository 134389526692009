<template>
  <div fluid>
    <v-toolbar>
      <v-row dense style="margin-top: 20px">
        <v-select
          style="margin-top: 4px; margin-right: 4px; max-width: 350px"
          :items="plan_list"
          v-model="plan"
          item-value="id"
          item-text="period"
          label="Plan list"
          outlined
          dense
          return-object
          @change="change_plan"
        >
        </v-select>
        <v-btn
          class="my-1"
          fab
          dark
          small
          :color="'indigo'"
          @click="OpenInfos()"
          >?</v-btn
        >
        <v-btn class="my-1" fab dark small :color="'indigo'" @click="newplan()"
          >+</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          style="padding: 4px"
          v-if="plan.isActif && current_operation.id > 0"
          @click.stop="action()"
          class="ma-1"
          color="blue darken-1"
        >
          {{ current_operation.label }}
        </v-btn>
        <v-btn
          v-if="Focalpoint"
          style="padding: 4px"
          @click.stop="attribution(1)"
          class="ma-1"
          color="blue darken-1"
        >
          Focal point
        </v-btn>
        <v-btn
          v-if="execution"
          style="padding: 4px"
          @click.stop="attribution(2)"
          class="ma-1"
          color="blue darken-1"
        >
          Execution
        </v-btn>
      </v-row>
    </v-toolbar>
    <listitems
      :title="'Tests List'"
      :list="data_list"
      :headers="headers"
      :showstd="true"
      :showedit="false"
      :master="true"
      :key="cor_key"
      :add="false"
      :del="false"
      @rowselect="select_test"
      @col_btn1_click="WaiverOpen"
      :btnA_disa="true"
    >
    </listitems>
    <waiter :showWait="showWait"> </waiter>
    <waiverform
      :dialog="waiver_dialog"
      :waivers="waivers"
      :plan="plan"
      :test="test_sel"
      :key="wkey"
      :edit="
        autorisations.findIndex((a) => a == '03002') >= 0 &&
        plan.isActif == 1 &&
        plan.profile_selected == 0
      "
      :accept="
        autorisations.findIndex((a) => a == '04002') >= 0 &&
        plan.profile_selected == 0
      "
      @close_waiver="close_waiver"
    ></waiverform>
    <attrform
      :tests="data_list"
      :dialog="attr_dialog"
      :agents="agents"
      :key="katt"
      :operation="attr_operation"
      @close_attr="close_attr"
      :plan="plan"
      :att_fp="
        plan.isActif == 1 &&
        autorisations.findIndex((a) => a == '06002') >= 0 &&
        plan.is_validated == 0
      "
      :att_ex="
        plan.isActif == 1 &&
        autorisations.findIndex((a) => a == '07002') >= 0 &&
        plan.is_validated == 0
      "
    ></attrform>
    <planinfo
      :key="kinfo"
      :dialog="infodialog"
      :infos="infos"
      :plan="plan"
      @CloseInfos="CloseInfos"
    >
    </planinfo>
    <confirmdialog ref="confirm" />
    <confirmdialog ref="accept" />
  </div>
</template>
// Roles permission and waiver contraintes (count and disgrace)
<script>
import Data from "../graphql/Planning/Data.gql";
import TestsPlanSelect from "../graphql/Planning/TestPlanSelect.gql";
import TestsWaivers from "../graphql/Planning/TestWaivers.gql";
import TestsCreate from "../graphql/Planning/TestsCreate.gql";
import PlanAccept from "../graphql/Planning/PlanAccept.gql";
import PlanInfo from "../graphql/Planning/PlanningStatut.gql";
export default {
  components: {
    waiter: () => import("../components/Widgets/Waiter.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
    waiverform: () => import("../components/Waiver_List.vue"),
    attrform: () => import("../components/TestAttribut.vue"),
    planinfo: () => import("../components/PlanningInfo.vue"),
  },
  data() {
    return {
      Focalpoint: false,
      execution: false,
      infos: [],
      kinfo: 100000,
      infodialog: false,
      wkey: 0,
      showSelect: 0,
      plan_min_date: null,
      cs: 10000,
      cor_key: 20000,
      cor_key2: 200000,
      katt: 300000,
      showWait: false,
      attr_operation: 1,
      waiver_dialog: false,
      attr_dialog: false,
      test_sel: {},
      agents: [],
      headers: [
        {
          text: "Well",
          value: "wellname",
          selected: true,
        },
        {
          text: "Type",
          value: "welltype",
          selected: true,
        },
        {
          text: "Test",
          value: "description",
          selected: true,
        },
        {
          text: "Last Date",
          value: "last_test",
          selected: true,
        },
        {
          text: "Start Date",
          value: "start_date",
          selected: true,
        },
        {
          text: "Finish Date",
          value: "end_date",
          selected: true,
        },
        {
          text: "Due Date",
          value: "due_date",
          selected: true,
        },
        {
          text: "Waiver Alternative Date",
          value: "waiver",
          selected: true,
        },
        {
          text: "Waiver Count",
          value: "waiver_count",
          selected: true,
        },
        {
          text: "Edit Waiver",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
        },
      ],

      klist: 1,
      selbtn: 10000,
      tests_types: [],
      statuts: [],

      data_list: [], ///list de tests
      waivers: [], //list des waivers
      waiver: {},
      plan_list: [], //list des plan
      plan: {
        id: null,
        date_from: null,
        date_to: null,
        statut_id: null,
        periode: null,
      },

      autorisations: [],
    };
  },
  computed: {
    startdate() {
      return this.plan_min_date ? this.plan_min_date : this.$store.state.today;
    },
    current_operation() {
      let label = { id: -1, value: "" };
      //select
      if (
        this.plan.parent_selected == 1 &&
        this.plan.profile_selected == 0 &&
        this.plan.role_has_waiver == 0 &&
        this.plan.is_attributed1 == 0 &&
        this.plan.is_validated == 0 &&
        this.autorisations.findIndex((a) => a == "02002") >= 0
      )
        label = { id: 1, label: "Accept" };
      //validate
      else if (
        this.plan.profile_selected == 1 &&
        this.plan.all_selected == 1 &&
        this.plan.role_has_waiver == 0 &&
        this.plan.parent_selected == 1 &&
        this.plan.is_attributed2 == 1 && //
        this.plan.is_validated == 0 &&
        this.autorisations.findIndex((a) => a == "05002") >= 0
      )
        label = { id: 2, label: "Validate" };
      return label;
    },
  },
  created() {
    this.getdata();
    this.cs++;
    this.autorisations = this.$store.state.me.autorisations.split(",");
    //01002 read
    //02002 accept or select
    //05002 validate
    //06002 att. fp---
    //07002 att. ex--
    //03002 Ask  Waiver
    //04002 Accept/Reject Waiver
  },
  methods: {
    async OpenInfos() {
      this.showWait = true;
      let r = await this.$requette(
        PlanInfo,
        {
          plan_id: this.plan.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.infos = r.data.state_plannings;
        this.infodialog = true;
      }

      this.showWait = false;

      this.kinfo++;
    },
    CloseInfos() {
      this.infodialog = false;
    },
    newplan() {
      this.create_plan();
    },
    change_plan(item) {
      this.plan = item;
      this.getplan(item.id);
    },
    select_test(item) {
      this.test_sel = item;
    },

    action() {
      let id = this.current_operation.id;
      if (id == 1) this.select_plan(1);
      else if (id == 2) this.select_plan(2);
      if (id == 3) this.showattribution(1);
      if (id == 4) this.showattribution(2);
    },

    async select_plan(statut_id) {
      if (
        await this.$refs.accept.open(
          "Confirmation",
          +statut_id == 1
            ? "Select current planning"
            : "Validate  current planning",
          { color: "orange darken-3" }
        )
      ) {
        this.showWait = true;
        //  let v={id:this.plan.id,}
        let r = await this.$requette(
          PlanAccept,
          {
            plan: parseInt(this.plan.id),
            statut_id: statut_id,
            event_id: 2,
            user_id: this.$store.state.me.id,
          },
          "no-cache"
        );
        if (r.ok) {
          this.getdata(this.plan.id);
          this.showplan = false;
        }
        this.showWait = false;
      }
    },
    async create_plan() {
      let dt_from = new Date();

      dt_from.setMonth(dt_from.getMonth() + 1);

      let d0 = new Date(
        dt_from.getFullYear(),
        dt_from.getMonth(),
        1
      ).toLocaleDateString("en-US");
      let d1 = new Date(
        dt_from.getFullYear(),
        dt_from.getMonth() + 1,
        0
      ).toLocaleDateString();
      if (this.plan_list.length > 0) {
        let id = Math.max(...this.plan_list.map((o) => o.id));
        let k = this.plan_list.findIndex((x) => x.id == id);
        // if (this.plan_list[k].next_date_to <= d0) {
        d0 = this.plan_list[k].next_date_from;
        d1 = this.plan_list[k].next_date_to;
        // }
      }
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Create new planning  from " + d0 + " to " + d1,
          { color: "orange darken-3" }
        )
      ) {
        this.save_plan(d0, d1);
      }

      this.cs++;
      this.showplan = true;
    },
    async save_plan(d0, d1) {
      this.showWait = true;
      let r = await this.$requette(
        TestsCreate,
        {
          user_id: this.$store.state.me.id,
          date_from: d0,
          date_to: d1,
        },
        "no-cache"
      );
      if (r.ok) {
        this.getdata(r.data.UpsertPlanning.id);
      }
      this.showWait = false;
    },
    WaiverSelect(item) {
      this.waiver = item;
      this.event_headers[5].disabled = item.edit == 0;
    },
    async WaiverOpen(item) {
      this.showWait = true;
      let r = await this.$requette(
        TestsWaivers,
        {
          test_id: item.id,
          plan_id: this.plan.id,
          user_id: this.$store.state.me.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.waivers = r.data.tests_events;

        this.waiver_dialog = true;
        this.wkey++;
      }
      this.showWait = false;
    },

    close_waiver(item) {
      if (item) {
        this.getdata(item);
      } else this.waiver_dialog = false;
    },
    close_attr(item) {
      if (item) {
        this.change_plan(this.plan);
        this.cor_key++;
        this.cor_key2++;
      }
      this.attr_dialog = false;
      this.selbtn++;
    },
    attribution(operation) {
      this.attr_operation = operation;
      this.attr_dialog = true;
      this.katt++;
    },
    async getdata(id) {
      this.showWait = true;
      let r = await this.$requette(
        Data,
        {
          user_id: this.$store.state.me.id,
        },
        "no-cache"
      );
      if (r.ok) {
        this.agents = r.data.agents;
        this.plan_list = r.data.plannings;

        if (this.plan_list.length > 0) {
          let k = -1;
          if (id) k = this.plan_list.findIndex((x) => x.id == id);
          else k = this.plan_list.findIndex((x) => x.isCurrent == 1);
          if (k >= 0) {
            this.plan = this.plan_list[k];
          } else {
            this.plan = this.plan_list[0];
            this.create_plan();
          }

          this.getplan(this.plan.id);
        }
      }

      this.showWait = false;
    },
    async getplan(id) {
      this.data_list = [];
      if (this.plan.parent_selected) {
        this.showWait = true;
        let r = await this.$requette(
          TestsPlanSelect,
          {
            plan_id: parseInt(id),
            user_id: this.$store.state.me.id,
          },
          "no-cache"
        );
        if (r.ok) {
          this.data_list = r.data.get_plan;

          this.Focalpoint = this.plan.profile_selected == 1;
          this.execution = this.plan.is_validated == 1;
        }
        this.showWait = false;

        this.cor_key2++;
      }
      this.cor_key++;
    },

    search() {},
  },
};
</script>
<style scoped>
.center {
  text-align: center;
  background-color: #b8ababc0 !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  height: 30px;
  width: 200px;
}
.v-data-table >>> th {
  white-space: nowrap;
  border-bottom: none !important;
  /* border-right: 1px solid grey; */
}
.v-data-table >>> tr:hover {
  background: transparent !important;
}
.v-data-table >>> tr:nth-of-type(even) {
  background-color: transparent !important;
}

.v-data-table >>> tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.v-data-table >>> td {
  border-bottom: none !important;
  /* border-right: 1px solid grey; */
}

.v-tcard,
.v-card:hover {
  background-color: #b8ababc0 !important;
  cursor: pointer;
}
</style>
